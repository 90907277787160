import cn from "classnames";
import type { ReactNode } from "react";
import React from "react";

interface Props {
  Component?: JSX.ElementType;
  className?: string;
  href?: string;
  unpadded?: boolean;
  flat?: boolean;
  onClick?: () => void;
  onMouseOver?: () => void;
  onMouseLeave?: () => void;
  children: ReactNode;
}

const Card = React.forwardRef<HTMLDivElement, Props>(
  ({ children, className, Component = "div", unpadded = false, flat = false, ...rest }, ref) => {
    const rootClassName = cn(
      "bg-white rounded-lg",
      {
        "shadow-card": !flat,
        "border border-gray-200": flat,
        "p-4 md:py-6 md:px-8": !unpadded,
        "p-0 overflow-hidden": unpadded,
        "hover:border-gray-300 hover:shadow-card-hover focus:outline-none focus:ring-1 focus:ring-indigo-600 text-left":
          Component === "button" || Component === "a",
      },
      className
    );

    return (
      <Component ref={ref} {...rest} className={rootClassName}>
        {children}
      </Component>
    );
  }
);

Card.displayName = "Card";

export default Card;
