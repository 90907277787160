import type { GetServerSideProps, NextApiRequest } from "next";
import Image from "next/image";
import { useRouter } from "next/router";
import { csrfToken, getSession, providers } from "next-auth/client";
import header from "public/images/headers/hero.jpg";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import Title from "~/components/atoms/Title";
import { EmailOrCredentialsProvider, OAuthProvider } from "~/components/auth";
import { Logo } from "~/components/common";
import { Card } from "~/components/ui";
import { findCurrentUser } from "~/lib/server/responseHelpers";
import { isAdmin, isClient, isFarmer, isLabUser, isSampler } from "~/lib/user";

interface Props {
  providers: Array<{ name: string; id: string }>;
  csrfToken: string;
}

const IndexPage = ({ providers, csrfToken }: Props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [context, setContext] = useState<"login" | "signup">("signup");

  return (
    <div className="relative h-screen">
      <div className="z-50 w-full pt-4 mx-auto -mb-64 text-center md:pt-16 lg:h-full max-w-7xl lg:text-left lg:py-24">
        <div className="relative h-full px-4 lg:w-1/2 sm:px-8 xl:pr-16">
          <Logo className="pb-8 mx-auto text-5xl lg:mx-0" />
          <h1 className="text-3xl font-medium tracking-tight text-gray-900 sm:text-3xl md:text-4xl">
            <span className="block p-4 -m-4 font-serif text-transparent bg-clip-text lg:bg-gradient-to-tr bg-gradient-to-r from-indigo-700 to-blue-500">
              {t("index.tagline", "Healthy soils. Better yields.")}
            </span>
          </h1>
          <p className="max-w-md pb-12 mx-auto mt-3 text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
            {t("index.subtext", "More regular, less expensive, more profitable, more sustainable.")}
          </p>
          <Card className="z-50 w-full mx-auto bg-white sm:max-w-md lg:mr-auto lg:ml-0">
            <Title level={3} className="mb-4 font-overline">
              {t(`index.connexionTitle.${context}`, "Create a Genesis account")}
            </Title>
            {router.query.error && (
              <div className="px-4 py-2 mb-4 text-sm text-red-900 bg-red-100 border-red-400 rounded">
                {t(
                  `auth.error.${router.query.error}`,
                  "There was an error during authentication. Please try again."
                )}
              </div>
            )}
            <div>
              <EmailOrCredentialsProvider
                csrfToken={csrfToken}
                context={context}
                setContext={setContext}
              />
              <hr className="my-6 text-gray-300" />
            </div>
            {Object.values(providers).map((provider) => {
              // all other oauth providers (currently just google)
              if (provider.id !== "email" && provider.id !== "credentials") {
                return (
                  <div key={provider.id} className="mt-4">
                    <OAuthProvider provider={provider} context={context} />
                  </div>
                );
              }
            })}
          </Card>
        </div>
      </div>
      <div className="relative w-full h-[40rem] lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full -z-10">
        <Image objectFit="cover" layout="fill" src={header} alt="" className="-z-10" />
      </div>
    </div>
  );
};

export const getServerSideProps: GetServerSideProps<any> = async (context) => {
  const { req, res, query } = context || {};
  const session = await getSession({ req });

  const { callbackUrl } = query || {};

  if (session && res && session.accessToken) {
    const currentUser = await findCurrentUser(req as NextApiRequest, [
      "farmer",
      "client",
      "sampler",
      "admin",
      "labUser",
    ]);

    let redirect = "/sign-up";

    if (isAdmin(currentUser)) {
      redirect = "/admin";
    } else if (isClient(currentUser)) {
      redirect = "/org";
    } else if (isFarmer(currentUser)) {
      redirect = "/farmers";
    } else if (isSampler(currentUser)) {
      redirect = "/samplers";
    } else if (isLabUser(currentUser)) {
      redirect = "/lab";
    }

    return {
      redirect: {
        permanent: false,
        destination: callbackUrl ?? redirect ?? "/sign-up",
      },
      props: {},
    };
  }

  return {
    props: {
      providers: (await providers()) ?? {},
      csrfToken: await csrfToken(context),
    },
  };
};

export default IndexPage;
