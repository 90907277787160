import { Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Button, TextInput } from "~/components/ui";
import useAccountInfo from "~/hooks/useAccountInfo";
import { isError } from "~/lib/applicationError";

const EmailOrCredentialsProvider = ({
  csrfToken,
  context,
  setContext,
}: {
  csrfToken: string;
  context: "login" | "signup";
  setContext: (context: "login" | "signup") => void;
}) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState<string>("");
  const [prefersPassword, setPrefersPassword] = useState(false);
  const [email, setEmail] = useState<string>("");
  const [checkedEmail, setCheckedEmail] = useState<string>("");
  const [pendingVerification, setPendingVerification] = useState(false);

  const reset = () => {
    setCheckedEmail("");
    setPassword("");
    setPrefersPassword(false);
  };

  const { accountInfo, isLoading } = useAccountInfo(checkedEmail);

  useEffect(() => {
    if (!accountInfo || isError(accountInfo)) {
      return;
    } else if (!accountInfo.exists) {
      setPrefersPassword(true);

      if (context !== "signup") {
        setContext("signup");
      }
    } else if (accountInfo.exists) {
      if (accountInfo.hasPassword) {
        setPrefersPassword(true);
      }
      if (context !== "login") {
        setContext("login");
      }
    }
  }, [accountInfo, setContext, context]);

  useEffect(() => {
    // automatically sign-in the user if their account exists and they don't have a password.
    if (accountInfo && !accountInfo.hasPassword && accountInfo.exists) {
      setPendingVerification(true);
      (document.getElementById("emailProviderForm")! as HTMLFormElement).submit();
    }
  }, [accountInfo]);

  const action = prefersPassword
    ? context === "signup"
      ? "/api/auth/signup"
      : "/api/auth/callback/credentials"
    : "/api/auth/signin/email";

  return (
    <div>
      <form method="post" action={action} id="emailProviderForm">
        <TextInput
          value={email}
          onFocus={reset}
          onChange={(e) => setEmail(e.target.value)}
          className="pb-2"
          title={t("auth.email-address", "Email address")}
          type="email"
          id="email"
          autoFocus
          name="email"
          required={true}
          placeholder="alice@genesis.live"
        />
        {!accountInfo && (
          <Button
            type="primary"
            block
            htmlType="button"
            onClick={() => setCheckedEmail(email)}
            loading={isLoading}
          >
            {t("button.continue", "Continue")}
          </Button>
        )}

        <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
        <Transition
          show={!!checkedEmail && !!accountInfo}
          enter="transition ease-out duration-200"
          enterFrom="h-0 opacity-0"
          enterTo="h-auto opacity-100"
          leave="transition ease-in duration-150"
          leaveFrom="h-auto opacity-100"
          leaveTo="h-0 opacity-0"
        >
          {prefersPassword && (
            <TextInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              title={t("auth.password", "Password")}
              type="password"
              id="password"
              name="password"
              autoFocus
              required={true}
              minLength={8}
              placeholder={t("auth.password", "Password")}
            />
          )}

          {context === "login" && prefersPassword && (
            <button
              className="pt-2 text-sm text-center text-gray-700 hover:underline"
              type="button"
              onClick={() => {
                setPrefersPassword(false);
                setPassword("");
              }}
            >
              {t("userSettings.forgotPassword", "Forgot your password?")}
            </button>
          )}
          {context === "signup" && (
            <p className="py-2 text-sm text-left text-gray-700">
              {t(
                "form.passwordMinLength",
                "Passwords must be at least {{count}} characters long.",
                { count: 8 }
              )}
            </p>
          )}

          <Button
            type="primary"
            htmlType="submit"
            block
            className="mt-2"
            loading={pendingVerification}
          >
            {t(
              `auth.email-cta.${context}${prefersPassword ? "WithPassword" : "WitoutPassword"}`,
              "Sign in with Email",
              "button"
            )}
          </Button>
        </Transition>
      </form>
    </div>
  );
};

export default EmailOrCredentialsProvider;
