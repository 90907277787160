import type { SWRConfiguration } from "swr";
import useSWR from "swr";

import ApplicationError, { isError } from "~/lib/applicationError";
import type { EndpointResponse } from "~/lib/types";
import type { AccountInfoResponse } from "~/pages/api/auth/account-info";

interface UseAccountInfoResponse {
  accountInfo: AccountInfoResponse | undefined;
  isLoading: boolean;
}

const useAccountInfo = (email?: string | null): UseAccountInfoResponse => {
  const defaultOptions: SWRConfiguration = {
    revalidateOnFocus: false,
  };
  const res = useSWR<EndpointResponse<AccountInfoResponse>>(
    email ? `/api/auth/account-info?email=${encodeURIComponent(email)}` : null,
    defaultOptions
  );

  if (res.data && isError(res.data)) {
    throw new ApplicationError(res.data.error);
  }

  return { accountInfo: res.data, isLoading: res.isLoading };
};

export default useAccountInfo;
