export default class ApplicationError extends Error {
  public type: ApplicationErrorType;
  public details: unknown | undefined;
  public status: number | undefined;

  constructor(type: ApplicationErrorType, details?: unknown, status?: number) {
    super(type);
    this.type = type;
    this.details = details;
    this.status = status;
  }
}

type ValidationDetails = {
  path: (string | number)[];
  message: string;
};

// If you want an endpoint to respond with validation errors but you want it to
// report an error then raise this within a route handler
export class ValidationError extends Error {
  public details: unknown | undefined;
  public status: number | undefined;
  public validationErrors: ValidationDetails[] | undefined;

  constructor(message: ApplicationErrorType, validationErrors: ValidationDetails[]) {
    super(message);

    this.validationErrors = validationErrors;
    this.details = undefined;
  }
}

// Error types are translation keys
export type ApplicationErrorType =
  | "error.unauthorized" // the user does not have sufficient rights to access the page or data
  | "error.googleCloud.unsetCredentials" // Google Cloud credentials are not set in the environment.
  | "error.badUserType" // The user does not have the right user type to perform this action or API call
  | "error.notAllowed" // the HTTP method is not allowed
  | "error.badRequest" // the query is malformed
  | "error.notFound" // Id not found
  | "error.barCode.generation" // There was an error processing the barCode generation
  | "error.barCode.decoding" // there was an error decoding a barcode
  | "error.barCode.aleadyScanned" // the barcode has already been scanned
  | "error.passwordUpdateFailed" // the password update failed
  | "error.unknown";

export interface ErrorResponse {
  error: ApplicationErrorType;
  details?: unknown;
}

export interface ValidationErrorResponse {
  error: ApplicationErrorType;
  details?: unknown;
  validationErrors: {
    path: (string | number)[];
    message: string;
  }[];
}

export const isError = (res: unknown): res is ErrorResponse => {
  return !!res && (res as ErrorResponse).error !== undefined;
};

export const isCriticalError = (res: unknown): res is ErrorResponse => {
  return (
    !!res &&
    (res as ErrorResponse).error !== undefined &&
    (res as ValidationErrorResponse).validationErrors === undefined
  );
};

export const isValidationError = (res: unknown): res is ValidationError => {
  return !!res && (res as ValidationErrorResponse).validationErrors !== undefined;
};

export const isApplicationError = (err: unknown): err is ApplicationError => {
  return err instanceof ApplicationError;
};

export const statusFromType = (type: ApplicationErrorType) => {
  switch (type) {
    case "error.unauthorized":
      return 401;
    case "error.badUserType":
      return 403;
    case "error.notFound":
      return 404;
    case "error.notAllowed":
      return 405;
    case "error.googleCloud.unsetCredentials":
      return 500;
    default:
      return 400;
  }
};
